export const initialErrorsState = {}

const errorsReducer = (state = initialErrorsState, { type, errors }) => {
  switch (type) {
    case 'CLEAR_ERRORS':
      return initialErrorsState
    case 'SET_ERRORS':
      return errors
    default:
      return state
  }
}

export default errorsReducer
