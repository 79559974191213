/* globals localStorage */

export const localStorageClear = () => {
  return async (dispatch, getState) => {
    try {
      localStorage.removeItem('state')
    } catch (error) {
      console.log(error)
    }
  }
}

export const localStorageLoad = () => {
  let saved
  try {
    saved = localStorage.getItem('state')
  } catch (error) {
    console.log(error)
  }

  if (saved) {
    saved = JSON.parse(saved)

    return {
      list: saved.list,
      type: 'LOAD_LOCAL_STORAGE',
    }
  } else {
    return {}
  }
}

export const localStorageSave = ({ obj }) => {
  return async (dispatch, getState) => {
    try {
      localStorage.setItem('state', JSON.stringify({ obj }))
    } catch (error) {
      console.log(error)
    }
  }
}
