import styled from '@emotion/styled'
import debounce from 'lodash/debounce'
import Router from 'next/router'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { clearSearchAndReturn, setSearch } from '../redux/actions'
import MagnifyingGlass from './Icons/MagnifyingGlass'
import X from './Icons/X'

const Container = styled.form`
  height: 20px;
  padding-right: 19px;
  position: relative;
`

const Input = styled.input`
  appearance: none;
  background-color: transparent;
  border-radius: 0;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.blue};
  height: 20px;
  outline: none;
  width: 100%;

  &:focus,
  &:active {
    box-shadow: none;
    border-width: 0 0 1px 0;
  }

  &:invalid {
    border-width: 0 0 1px 0;
  }

  // clears the extra 'X' from Chrome and IE
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
  &::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }
  &::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }
`

const StyledMagnifyingGlass = styled(MagnifyingGlass)`
  position: absolute;
  top: 0;
  right: 0;
`

const StyledX = styled(X)`
  cursor: pointer;
  position: absolute;
  top: 3px;
  right: 2px;
`

const SearchInput = ({ className }) => {
  const { preSearchPath, searchQuery } = useSelector((state) => state.search)
  const [query, setQuery] = useState(searchQuery)
  const dispatch = useDispatch()

  const search = () => {
    if (!query) return

    let newPreSearchPath = preSearchPath
    if (Router.asPath.indexOf('/search') === -1) {
      newPreSearchPath = Router.asPath
    }

    dispatch(
      setSearch({
        preSearchPath: newPreSearchPath,
        searchQuery: query,
      })
    )

    Router.push(`/search/${encodeURIComponent(query)}`)
  }

  const delayedSearch = useCallback(debounce(search, 500), [query])

  useEffect(() => {
    delayedSearch()
    return delayedSearch.cancel
  }, [query, delayedSearch])

  useEffect(() => {
    if (query != searchQuery) setQuery(searchQuery)
  }, [searchQuery])

  const handleChange = (e) => {
    setQuery(e.target.value)
  }

  const handleClose = (e) => {
    setQuery('')
    dispatch(clearSearchAndReturn())
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    search(e.target.value)
  }

  return (
    <Container role="search" className={className} onSubmit={handleSubmit}>
      <Input onChange={handleChange} type="search" value={query} />
      {query ? (
        <StyledX onClick={handleClose} size="15" />
      ) : (
        <StyledMagnifyingGlass />
      )}
    </Container>
  )
}

export default SearchInput
