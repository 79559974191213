import styled from '@emotion/styled'

const User = styled.span`
  background: ${(props) => props.color};
  border-radius: 64px 64px 0 0/64px;
  border: 2px solid ${(props) => props.color};
  display: inline-block;
  height: ${(props) => props.size * 2.39 + props.sizeUnit};
  margin: ${(props) => props.size * 1.6 + props.sizeUnit} 1px 2px;
  width: ${(props) => props.size * 3.27 + props.sizeUnit};

  &::before {
    background: ${(props) => props.color};
    border-radius: 50%;
    border: 2px solid ${(props) => props.color};
    content: '';
    height: ${(props) => props.size * 1.8 + props.sizeUnit};
    left: 50%;
    position: absolute;
    top: ${(props) => -props.size * 1.9 + props.sizeUnit};
    transform: translateX(-50%);
    width: ${(props) => props.size * 1.8 + props.sizeUnit};
  }
`

User.defaultProps = {
  color: '#1a00ff',
  size: '10',
  sizeUnit: 'px',
}

export default User
