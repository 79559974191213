import { useSelector } from 'react-redux'

const className = (type) => {
  switch (type) {
    case 'success':
      return 'b green mt2 mb3'
    case 'notice':
      return 'b gray mt2 mb3'
    case 'error':
      return 'b red mt2 mb3'
    default:
      return 'gray'
  }
}

const Flash = () => {
  const { message, type } = useSelector((state) => state.flash)

  if (message) {
    return <span className={className(type)}>{message}</span>
  } else {
    return null
  }
}

export default Flash
