import { areCookiesEnabled, checkIdentityCookie } from '../../lib/cookies'
import { checkPlayCount } from '../../lib/http'
import { feedbackPost } from './feedback'

const ERROR_TIMEOUT = 5000

export const audioLoaded = () => ({
  type: 'AUDIO_LOADED',
})

export const playerClose = () => ({
  type: 'PLAYER_CLOSE',
})

export const playerLoad = ({ list, playIndex, playlist, release }) => {
  return async (dispatch, getState) => {
    const { playingListId, volume } = getState().player

    if (list) {
      dispatch(
        feedbackPost({
          type: 'list:play',
          releaseUrl: playlist[playIndex].url,
          list: list.id,
        })
      )
    } else if (release) {
      dispatch(
        feedbackPost({
          type: 'list_item:play',
          releaseUrl: release.url,
          list_item: release.id,
        })
      )
    }

    const bmcIdentity = checkIdentityCookie()
    const cookiesEnabled = areCookiesEnabled()

    if (!cookiesEnabled) {
      dispatch(
        playerSetError(
          'Cookies must be enabled in your browser to use the music player.'
        )
      )
      setTimeout(() => {
        dispatch(playerClose())
      }, ERROR_TIMEOUT)
      return false
    }

    if (list && list.id != playingListId) {
      const response = await checkPlayCount({ bmcIdentity, slug: list.slug })

      if (!response.ok) {
        if (response.status === 429) {
          dispatch(
            playerSetError(
              "You've reached your play limit for this list. Please consider buying the music."
            )
          )
          setTimeout(() => {
            dispatch(playerClose())
          }, ERROR_TIMEOUT)
          return false
        } else if (response.status == 422) {
          dispatch(
            playerSetError(
              'Cookies must be enabled in your browser to use the music player.'
            )
          )
          setTimeout(() => {
            dispatch(playerClose())
          }, ERROR_TIMEOUT)
          return false
        }
      }
    }

    dispatch(
      playerLoadState({
        open: true,
        playIndex: playIndex,
        playingListId: list ? list.id : null,
        playingReleaseId: release ? release.id : null,
        playlist,
        volume,
      })
    )
  }
}

const playerLoadState = (player) => ({
  player,
  type: 'PLAYER_LOAD',
})

export const playerNext = () => ({
  type: 'PLAYER_NEXT',
})

export const playerPause = () => ({
  type: 'PLAYER_PAUSE',
})

export const playerPlay = () => ({
  type: 'PLAYER_PLAY',
})

export const playerPrevious = () => ({
  type: 'PLAYER_PREVIOUS',
})

export const playerSetError = (error) => ({
  player: { error },
  type: 'PLAYER_SET_ERROR',
})

export const setVolume = (volume) => ({
  player: { volume },
  type: 'PLAYER_SET_VOLUME',
})

export const refreshRelease = ({ streamingUrl }) => {
  return async (dispatch) => {
    return false

    // dispatch(
    //   playerLoadState({
    //     player: {
    //       open: true,
    //       playIndex: playIndex,
    //       playlist,
    //     },
    //     type: 'PLAYER_LOAD',
    //   })
    // )
  }
}
