import { useState } from 'react'
import styled from '@emotion/styled'
import Link from 'next/link'
import Avatar from '../Avatar'

const Wrapper = styled.div`
  cursor: pointer;
  padding-left: 4rem;
  position: relative;
`

const Dropdown = styled.div`
  border-radius: 0.125rem;
  background-color: ${({ theme }) => theme.colors.blue};
  box-shadow: 0px 4px 17px -12px rgba(0, 0, 0, 0.75);
  padding: 1rem 1.5rem 0 1.5rem;
  position: absolute;
  top: 2.8rem;
  right: 0;
  text-align: right;
  z-index: 9999;
`

const DropdownLink = styled.a`
  color: ${({ theme }) => theme.colors.background};
  font-size: 1rem;
  font-weight: bold;
  display: block;
  margin-bottom: 1.5rem;
  text-decoration: none;
  white-space: nowrap;

  &:hover {
    text-decoration: underline;
  }

  &:last-child {
    margin-bottom: none;
  }
`

const Username = styled.span`
  color: ${({ theme }) => theme.colors.background};
  display: block;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  text-decoration: underline;
  white-space: nowrap;
`

const AccountUser = ({ handleLogout, user }) => {
  const [open, setOpen] = useState(false)

  return (
    <Wrapper
      onClick={() => {
        setOpen(!open)
      }}
      onMouseLeave={() => {
        if (open) setOpen(false)
      }}
    >
      <Avatar src={user.avatar} username={user.username} />
      {open && (
        <Dropdown>
          <Link href={`/user/${user.username.toLowerCase()}`}>
            <Username>{user.username}</Username>
          </Link>
          <Link href="/my-lists">
            <DropdownLink href="/my-lists">My lists</DropdownLink>
          </Link>
          <Link href="/settings">
            <DropdownLink href="/settings">Settings</DropdownLink>
          </Link>
          <Link href="/donate">
            <DropdownLink href="/donate">Donate</DropdownLink>
          </Link>
          {user.admin && (
            <Link href="/admin">
              <DropdownLink href="/admin">Admin</DropdownLink>
            </Link>
          )}
          <DropdownLink href="#" onClick={handleLogout}>
            Logout
          </DropdownLink>
        </Dropdown>
      )}
    </Wrapper>
  )
}

export default AccountUser
