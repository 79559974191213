import Router from 'next/router'
import * as HTTP from '../../lib/http'
import { setErrors } from './errors'
import { setFlash } from './flash'
import { setLoading } from './index'

export const requestResetPassword = (data) => {
  return async (dispatch) => {
    const response = await HTTP.resetPassword(data)
    dispatch(setLoading(false))

    if (!response.ok) {
      const body = await response.json()
      dispatch(setFlash(body.error.message, 'error'))
    } else {
      await HTTP.logout()
      const flash = new URLSearchParams({
        flash: 'success',
        message:
          'Your password has been reset. Please use your new password to login',
      })
      Router.push(`/login?${flash}`)
    }
  }
}

export const sendResetPassword = (data) => {
  return async (dispatch) => {
    const response = await HTTP.sendResetPassword(data)
    dispatch(setLoading(false))

    if (!response.ok) {
      if (response.status === 404) {
        dispatch(setFlash("We don't have that email in our system.", 'error'))
      } else {
        dispatch(
          setFlash("We've encountered an error sending that email.", 'error')
        )
      }
    } else {
      dispatch(
        setFlash(
          'Check your inbox for a link to reset your password.',
          'success'
        )
      )
    }
  }
}

export const updateUser = (data) => {
  return async (dispatch) => {
    setLoading(true)
    const response = await HTTP.updateUser(data)
    const body = await response.json()
    setLoading(false)

    if (!response.ok) {
      dispatch(setFlash(body.error.message, 'error'))
      return dispatch(
        setErrors({
          [body.error.value]: body.error.message,
        })
      )
    } else {
      Router.push(`/user/${body.username.toLowerCase()}`)
    }
  }
}
