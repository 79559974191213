import Link from 'next/link'
import styled from '@emotion/styled'

const Wrapper = styled.div`
  a {
    color: ${({ theme }) => theme.colors.blue};
    font-size: 0.8rem;
    font-weight: bold;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    ${({ theme }) => theme.media.mUp} {
      font-size: 1rem;
    }
  }
`

const AccountStranger = ({ className }) => (
  <Wrapper className={className}>
    <Link href="/login">
      <a>Log In</a>
    </Link>
    <span> / </span>
    <Link href="/signup">
      <a>Sign Up</a>
    </Link>
  </Wrapper>
)

export default AccountStranger
