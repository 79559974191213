/* globals fetch */
import 'isomorphic-fetch'

const requestHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

export const assignListToUser = ({ token, username }) => {
  const options = {
    method: 'POST',
    headers: requestHeaders,
    credentials: 'include',
    body: JSON.stringify({ token }),
  }

  return fetch(`/api/user/${username}/lists`, options)
}

export const deleteList = ({ slug, token }) => {
  const options = {
    method: 'DELETE',
    credentials: 'include',
    headers: requestHeaders,
    body: JSON.stringify({ token }),
  }

  return fetch(`/api/list/${slug}/edit/${token}`, options)
}

export const deleteViewer = () => {
  const options = {
    method: 'DELETE',
    headers: requestHeaders,
    credentials: 'include',
  }

  return fetch('/api/user', options)
}

export const login = ({ username, password }) => {
  const options = {
    method: 'POST',
    headers: requestHeaders,
    credentials: 'include',
    body: JSON.stringify({ username, password }),
  }

  return fetch('/api/login', options)
}

export const logout = () => {
  const options = {
    method: 'GET',
    credentials: 'include',
    headers: requestHeaders,
  }

  return fetch('/api/logout', options)
}

export const checkPlayCount = ({ bmcIdentity, slug }) => {
  const options = {
    method: 'GET',
    credentials: 'include',
    headers: requestHeaders,
  }

  return fetch(
    `/api/list/${slug}/checkPlayCount?bmcIdentity=${bmcIdentity}`,
    options
  )
}

export const resetPassword = (obj) => {
  const options = {
    method: 'PUT',
    credentials: 'include',
    headers: requestHeaders,
    body: JSON.stringify({ ...obj }),
  }

  return fetch('/api/user/resetPassword', options)
}

export const search = ({ query }) => {
  const options = {
    method: 'GET',
    headers: requestHeaders,
    credentials: 'include',
  }

  return fetch(`/api/search?q=${query}`, options)
}

export const sendResetPassword = (obj) => {
  const options = {
    method: 'POST',
    credentials: 'include',
    headers: requestHeaders,
    body: JSON.stringify({ ...obj }),
  }

  return fetch('/api/user/resetPassword', options)
}

export const saveFeedback = (obj) => {
  const options = {
    method: 'POST',
    credentials: 'include',
    headers: requestHeaders,
    body: JSON.stringify({ ...obj }),
  }

  return fetch('/api/feedback', options)
}

export const createList = ({ list, draft }) => {
  const options = {
    method: 'POST',
    credentials: 'include',
    headers: requestHeaders,
    body: JSON.stringify({ ...list, draft }),
  }

  return fetch('/api/lists', options)
}

export const scrape = ({ url }) => {
  const options = {
    method: 'POST',
    credentials: 'include',
    headers: requestHeaders,
    body: JSON.stringify({ url }),
  }

  return fetch('/api/scrape', options)
}

export const signup = ({ email, password, username, verify }) => {
  const options = {
    method: 'POST',
    headers: requestHeaders,
    credentials: 'include',
    body: JSON.stringify({
      email,
      username,
      password,
      verify,
    }),
  }

  return fetch('/api/users', options)
}

export const updateHomepage = ({ collections }) => {
  const options = {
    method: 'PUT',
    credentials: 'include',
    headers: requestHeaders,
    body: JSON.stringify({ collections }),
  }

  return fetch('/api/collections', options)
}

export const updateList = ({ list, draft, token }) => {
  const options = {
    method: 'PUT',
    credentials: 'include',
    headers: requestHeaders,
    body: JSON.stringify({ ...list, draft, token }),
  }

  return fetch(`/api/list/${list.slug}/edit/${token}`, options)
}

export const updateUser = ({
  avatar,
  bio,
  email,
  hero_image,
  username,
  userId,
}) => {
  // eslint-disable-line
  const options = {
    method: 'PUT',
    credentials: 'include',
    headers: requestHeaders,
    body: JSON.stringify({ avatar, bio, email, hero_image, username, userId }),
  }

  return fetch(`/api/authUser`, options)
}
