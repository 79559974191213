import { useUser } from '../../hooks'
import AccountUser from './AccountUser'
import AccountStranger from './AccountStranger'

const Account = ({ className }) => {
  const [user, { mutate }] = useUser()

  const handleLogout = async () => {
    await fetch('/api/logout')
    mutate({ user: null })
  }

  return (
    <div className={className}>
      {user ? (
        <AccountUser handleLogout={handleLogout} user={user} />
      ) : (
        <AccountStranger />
      )}
    </div>
  )
}

export default Account
