import { ThemeProvider } from '@emotion/react'
import styled from '@emotion/styled'
import { Provider as StoreProvider } from 'react-redux'
import MaintenanceMessage from '../components/MaintenanceMessage'
import { EmbedLayout, MainLayout } from '../layouts'
import { globalStyles } from '../lib/globalStyles'
import '../public/css/tachyons.css'
import { useStore } from '../redux/store'
import { lightTheme } from '../themes'

const GlobalWrapper = styled.div`
  *:focus {
    outline-color: ${({ theme }) => theme.colors.blue};
  }

  input::placeholder,
  textarea::placeholder {
    color: ${({ theme }) => theme.colors.lightGray};
  }
`

export default function MyApp({ Component, pageProps, router }) {
  const store = useStore(pageProps.initialReduxState)

  if (process.env.NEXT_PUBLIC_MAINTENANCE_MODE == 'true') {
    return (
      <ThemeProvider theme={lightTheme}>
        {globalStyles}
        <MaintenanceMessage />
      </ThemeProvider>
    )
  }

  const Layout = router.pathname.startsWith('/embed/')
    ? EmbedLayout
    : MainLayout

  return (
    <StoreProvider store={store}>
      <ThemeProvider theme={lightTheme}>
        {globalStyles}
        <GlobalWrapper>
          <Layout>
            <Component {...pageProps} />
          </Layout>
        </GlobalWrapper>
      </ThemeProvider>
    </StoreProvider>
  )
}
