export const initialCollectionsState = []

const collectionsReducer = (
  state = initialCollectionsState,
  { type, collections }
) => {
  switch (type) {
    case 'EDIT_COLLECTION':
    case 'REMOVE_COLLECTION':
    case 'REORDER_HOMEPAGE':
    case 'SET_HOMEPAGE':
    case 'UPDATE_HOMEPAGE':
      return collections
    default:
      return state
  }
}

export default collectionsReducer
