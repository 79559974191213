import * as HTTP from '../../lib/http'

export const feedbackPost = (obj) => {
  return async (dispatch) => {
    const response = await HTTP.saveFeedback(obj)

    if (!response.ok) {
      console.log(
        `Error saving analytic: ${JSON.stringify({
          message: response.message,
          ...obj,
        })}`
      )
    }
  }
}
