import useSWR from 'swr'
import { get } from '../lib/fetch'

export function useUser() {
  const { data, mutate } = useSWR('/api/authUser', get)
  // if data is not defined, the query has not completed
  const loading = !data
  const user = data?.user
  return [user, { mutate, loading }]
}
