import styled from '@emotion/styled'
import Link from 'next/link'
import Account from './Account'
import Logo from './Logo'
import SearchInput from './SearchInput'

const Container = styled.header`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 52rem;
  margin-bottom: ${({ theme }) => theme.space[3]};
  margin-top: ${({ theme }) => theme.space[2]};
  position: relative;
  width: 100%;

  ${({ theme }) => theme.media.lUp} {
    margin-top: ${({ theme }) => theme.space[3]};
  }
`

const LogoLink = styled.a`
  text-decoration: none;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  &:visited {
    color: ${({ theme }) => theme.colors.text};
  }
`

const L = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;

  ${({ theme }) => theme.media.mUp} {
    align-items: space-between;
    flex-direction: row;
    justify-content: space-between;
    margin-right: ${({ theme }) => theme.space[2]};
    width: 100%;
  }

  ${({ theme }) => theme.media.lUp} {
    margin-right: ${({ theme }) => theme.space[3]};
  }
`

const StyledAccount = styled(Account)`
  flex: 0 0 auto;
`

const Header = () => (
  <Container>
    <L>
      <Link href="/">
        <LogoLink href="/">
          <Logo />
        </LogoLink>
      </Link>
      <SearchInput />
    </L>
    <StyledAccount />
  </Container>
)

export default Header
