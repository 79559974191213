export const initialPlayerState = {
  audioLoading: true,
  error: null,
  open: false,
  playIndex: -1,
  playing: false,
  playingListId: null,
  playingReleaseId: null,
  playlist: [],
  volume: 1.0,
}

const playerReducer = (state = initialPlayerState, { player, type }) => {
  switch (type) {
    case 'AUDIO_LOADED':
      return { ...state, audioLoading: false }
    case 'PLAYER_CLOSE':
      return { ...state, playing: false, open: false }
    case 'PLAYER_LOAD':
      return { ...player, audioLoading: true }
    case 'PLAYER_NEXT':
      if (state.playIndex === state.playlist.length - 1) {
        return state
      }
      return { ...state, playIndex: state.playIndex + 1 }
    case 'PLAYER_PAUSE':
      return { ...state, playing: false }
    case 'PLAYER_PLAY':
      return { ...state, playing: true }
    case 'PLAYER_PREVIOUS':
      if (state.playIndex === 0) {
        return state
      }
      return { ...state, playIndex: state.playIndex - 1 }
    case 'PLAYER_SET_ERROR':
      return { ...state, error: player.error, open: true, playing: false }
    case 'PLAYER_SET_VOLUME':
      return { ...state, volume: player.volume }
    default:
      return state
  }
}

export default playerReducer
