import styled from '@emotion/styled'

export default styled.p`
  font-size: 1rem;
  line-height: 1.5;

  ${({ theme }) => theme.media.mUp} {
    font-size: 1.25rem;
  }
`
