import Router from 'next/router'
import { setErrors } from './errors'
import { setFlash } from './flash'
import { setLoading } from './index'
import * as HTTP from '../../lib/http'

export const requestLogin = (user) => {
  return async (dispatch) => {
    dispatch(setLoading(true))
    const response = await HTTP.login(user)
    const body = await response.json()
    dispatch(setLoading(false))

    if (!response.ok) {
      return dispatch(setFlash(body.error.message, 'error'))
    }

    Router.push('/')
  }
}

export const requestLogout = () => {
  return async (dispatch) => {
    const response = await HTTP.logout()

    if (response.status === 200) {
      Router.push('/')
    } else if (response.status === 403) {
      Router.push('/')
    } else {
      return dispatch(setFlash('Internal server error', 'error'))
    }
  }
}

export const requestSignup = ({ email, password, username, verify, cb }) => {
  return async (dispatch) => {
    dispatch(setLoading(true))

    const response = await HTTP.signup({
      email,
      password,
      username,
      verify,
    })

    const body = await response.json()

    dispatch(setLoading(false))

    if (!response.ok) {
      if (body.error.value === 'email') {
        Router.push('/forgot-password')
        setTimeout(() => {
          dispatch(
            setFlash(
              'That email is already in use. You may recover your password here.',
              'error'
            )
          )
        }, 100)
      } else {
        return dispatch(
          setErrors({
            [body.error.value]: body.error.message,
          })
        )
      }
    } else {
      await cb(body)
      return Router.push('/settings')
    }
  }
}

export const viewerDelete = () => {
  return async (dispatch) => {
    const response = await HTTP.deleteViewer()

    if (!response.ok) {
      return dispatch(setFlash('Internal server error', 'error'))
    }

    return dispatch(requestLogout())
  }
}
