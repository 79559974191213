import Router from 'next/router'
import { setFlash } from './flash'
import { setLoading } from './index'
import { moveItem } from '../../lib/arrays'
import * as HTTP from '../../lib/http'

export const addCollection = (setEditIndex) => {
  return async (dispatch, getState) => {
    const { collections } = getState()

    collections.push({
      description: null,
      displayLength: 8,
      Lists: [],
      isNew: true,
      title: null,
    })

    dispatch(editCollection(collections.length - 1, setEditIndex))
    dispatch(updateCollections(collections, 'ADD_COLLECTION'))
  }
}

export const editCollection = (index, setEditIndex) => {
  return async (dispatch, getState) => {
    const { collections } = getState()
    const newIndex = collections.findIndex((collection) => collection.isNew)
    if (index !== newIndex && newIndex > -1)
      dispatch(removeCollection(newIndex))
    setEditIndex(index)
  }
}

export const removeCollection = (collectionIndex) => {
  return async (dispatch, getState) => {
    const { collections } = getState()
    const value = JSON.parse(JSON.stringify(collections))
    value.splice(collectionIndex, 1)
    dispatch(updateCollections(value, 'REMOVE_COLLECTION'))
  }
}

export const reorderHomepage = (collectionIndex, direction) => {
  return async (dispatch, getState) => {
    let { collections } = getState()
    collections = moveItem(collections, collectionIndex, direction)
    dispatch(updateCollections(collections, 'REORDER_HOMEPAGE'))
  }
}

export const setHomepage = (collections) => ({
  collections,
  type: 'SET_HOMEPAGE',
})

export const updateCollection = ({
  collectionIndex,
  description,
  Lists,
  title,
  setEditIndex,
}) => {
  return async (dispatch, getState) => {
    const { collections } = getState()
    collections[collectionIndex] = {
      description,
      displayLength: 8,
      Lists,
      title,
    }
    dispatch(editCollection(-1, setEditIndex))
    dispatch(updateCollections(collections, 'UPDATE_COLLECTION'))
  }
}

const updateCollections = (collections, type) => ({ collections, type })

export const updateHomepage = () => {
  return async (dispatch, getState) => {
    const { collections } = getState()

    dispatch(setLoading(true))
    const response = await HTTP.updateHomepage({ collections })
    const body = await response.json()
    dispatch(setLoading(false))

    if (!response.ok) {
      return dispatch(setFlash(body.error.message, 'error'))
    } else {
      Router.push('/')
    }
  }
}
