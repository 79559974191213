import Rollbar from 'rollbar'

export const getRollbarConfig = (token) => ({
  accessToken: token,
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: process.env.NEXT_PUBLIC_DISABLE_ROLLBAR != 'true',
  hostSafeList: ['buymusic.club', 'vercel.app', 'localhost'],
  payload: {
    environment: process.env.NODE_ENV,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: process.env.NEXT_BUILD_ID,
        guess_uncaught_frames: true,
      },
    },
  },
  verbose: true,
})

export const rollbarServer = new Rollbar(
  getRollbarConfig(process.env.ROLLBAR_SERVER_TOKEN)
)
