import styled from '@emotion/styled'

const Container = styled.div`
  display: inline-block;
  height: ${(props) => `${props.size}px`};
  padding: 0;
  overflow: hidden;
  width: ${(props) => `${props.size}px`};
`

const MagnifyingGlass = ({ className, color, onClick, size }) => (
  <Container className={className} onClick={onClick} size={size}>
    <svg width={size} height={size} x="0" y="0" viewBox={`0 0 ${size} ${size}`}>
      <g transform={`scale(${size / 48}) translate(-28 -26)`}>
        <path
          d="M 69.902,72.704 58.967,61.769 c -2.997,1.961 -6.579,3.111 -10.444,3.111 -10.539,0 -19.062,-8.542 -19.062,-19.081 0,-10.519 8.522,-19.061 19.062,-19.061 10.521,0 19.06,8.542 19.06,19.061 0,3.679 -1.036,7.107 -2.828,10.011 l 11.013,11.011 c 0.583,0.567 0.094,1.981 -1.076,3.148 l -1.64,1.644 c -1.17,1.167 -2.584,1.656 -3.15,1.091 z M 61.249,45.799 c 0,-7.033 -5.695,-12.727 -12.727,-12.727 -7.033,0 -12.745,5.694 -12.745,12.727 0,7.033 5.712,12.745 12.745,12.745 7.032,0 12.727,-5.711 12.727,-12.745 z"
          fill={color}
        />
      </g>
    </svg>
  </Container>
)

MagnifyingGlass.defaultProps = {
  color: '#1a00ff',
  size: 20,
}

export default MagnifyingGlass
