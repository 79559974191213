import { css, Global } from '@emotion/react'

export const globalStyles = (
  <Global
    styles={css`
      @font-face {
        font-family: 'Inter UI';
        font-style: normal;
        font-weight: 400;
        src: url('/fonts/Inter-UI-Regular.woff2') format('woff2'),
          url('/fonts/Inter-UI-Regular.woff') format('woff');
      }

      @font-face {
        font-family: 'Inter UI';
        font-style: normal;
        font-weight: 500;
        src: url('/fonts/Inter-UI-Medium.woff2') format('woff2'),
          url('/fonts/Inter-UI-Medium.woff') format('woff');
      }

      @font-face {
        font-family: 'Inter UI';
        font-style: normal;
        font-weight: 600;
        src: url('/fonts/Inter-UI-SemiBold.woff2') format('woff2'),
          url('/fonts/Inter-UI-SemiBold.woff') format('woff');
      }

      @font-face {
        font-family: 'Inter UI';
        font-style: normal;
        font-weight: 700;
        src: url('/fonts/Inter-UI-Bold.woff2') format('woff2'),
          url('/fonts/Inter-UI-Bold.woff') format('woff');
      }

      html {
        margin-left: calc(100vw - 100%);
      }

      body {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-family: Inter UI, -apple-system, BlinkMacSystemFont, Segoe UI,
          Roboto, Helvetica, Arial, sans-serif;
      }

      p {
        line-height: 1.5;
      }

      *,
      :before,
      :after {
        box-sizing: border-box;
      }

      .opacity-hover-child {
        transition: opacity 0.1s ease-in-out;
        opacity: 0;
      }

      .opacity-hover-host:hover > .opacity-hover-child {
        opacity: 1;
      }
      .opacity-hover-host:hover > div > .opacity-hover-child {
        opacity: 1;
      }

      ul > li:last-child {
        margin-bottom: 0rem !important;
      }
    `}
  />
)
