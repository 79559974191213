export const initialFlashState = {
  message: null,
  type: null,
}

const flashReducer = (state = initialFlashState, { type, flash }) => {
  switch (type) {
    case 'CLEAR_FLASH':
      return initialFlashState
    case 'SET_FLASH':
      return flash
    default:
      return state
  }
}

export default flashReducer
