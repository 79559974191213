import Router from 'next/router'
import * as HTTP from '../../lib/http'
import { setFlash } from './flash'
import { setLoading } from './loading'
import { localStorageClear } from './localStorage'

export const listCreate = ({ list, draft, user }) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true))
      const response = await HTTP.createList({ list, draft })
      const body = await response.json()

      if (!response.ok) {
        dispatch(setFlash(`Error saving list: ${body.error.message}`, 'error'))
        dispatch(setLoading(false))
      } else {
        if (user) {
          Router.push(`/list/${body.slug}`)
        } else {
          dispatch(
            listSuccess({
              slug: body.slug,
              token: body.token,
            })
          )
          dispatch(setLoading(false))
          Router.push('/success')
        }
        dispatch(localStorageClear())
      }
    } catch (error) {
      dispatch(setLoading(false))
      dispatch(setFlash('Error saving list', 'error'))
    }
  }
}

export const listDelete = ({ slug, token }) => {
  return async (dispatch) => {
    dispatch(setLoading(true))
    const response = await HTTP.deleteList({ slug, token })
    const body = await response.json()

    if (response.ok) {
      dispatch(setLoading(false))
      if (body.username) {
        Router.push('/my-lists')
      } else {
        Router.push('/')
      }
    } else {
      dispatch(setLoading(false))
      dispatch(setFlash('Error deleting list', 'error'))
    }
  }
}

export const listUpdate = ({ list, draft, token, user }) => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true))
      const response = await HTTP.updateList({ list, draft, token })
      const body = await response.json()

      if (response.ok) {
        dispatch(setLoading(false))
        if (user) {
          Router.push(`/list/${body.slug}`)
        } else {
          dispatch(
            listSuccess({
              slug: body.slug,
              token: body.token,
            })
          )
          Router.push('/success')
        }
        dispatch(localStorageClear())
      } else {
        dispatch(setLoading(false))
        dispatch(setFlash(`Error saving list: ${body.error.message}`, 'error'))
      }
    } catch (error) {
      dispatch(setLoading(false))
      dispatch(setFlash('Error saving list', 'error'))
    }
  }
}

export const listSuccess = ({ slug, token }) => ({
  success: { slug, token },
  type: 'LIST_SUCCESS',
})
