import styled from '@emotion/styled'
import PlayerWrapper from '../components/PlayerWrapper'

const playerHeight = '74px'

const FooterSpacer = styled.div`
  height: ${playerHeight};
`

const EmbedLayout = ({ children }) => (
  <PlayerWrapper>
    {children}
    <FooterSpacer />
    <div id="fixed-footer" />
  </PlayerWrapper>
)

export default EmbedLayout
