export const handleBrokenBandcampUrl = async ({
  externalId,
  handleError,
  handleRemoved,
  handleUpdated,
  url,
}) => {
  try {
    const response = await fetch(
      `/api/bandcamp?url=${encodeURIComponent(url)}&id=${externalId}`,
      { method: 'PUT' }
    )
    const body = await response.json()

    if (!response.ok) {
      console.log(`Error checking broken URL: ${body?.error?.message}`)
      if (!!handleError) handleError(body?.error)
      return false
    }

    if (body.removed) {
      if (!!handleRemoved) handleRemoved()
    } else {
      if (!!handleUpdated) handleUpdated(body)
    }
  } catch (error) {
    console.log(`Error checking broken URL: ${error}`)
    handleError(error)
  }
}
