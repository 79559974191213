export function areCookiesEnabled() {
  var cookieEnabled = navigator.cookieEnabled
  if (!cookieEnabled) {
    document.cookie = 'testcookie=test; SameSite=Lax'
    cookieEnabled = document.cookie.indexOf('testcookie') != -1
  }
  return cookieEnabled
}

export function checkIdentityCookie() {
  let idCookie = getCookie({ name: 'bmcIdentity' })

  if (!idCookie) {
    idCookie = Math.random().toString(16).slice(2)

    try {
      setCookie({
        expDays: 30,
        name: 'bmcIdentity',
        value: idCookie,
      })
    } catch (e) {
      console.log('error setting cookie', e)
      return 'error'
    }
  }

  return idCookie
}

function getCookie({ name }) {
  const cName = name + '='
  const cDecoded = decodeURIComponent(document.cookie)
  const cArr = cDecoded.split('; ')
  let res
  cArr.forEach((val) => {
    if (val.indexOf(cName) === 0) res = val.substring(cName.length)
  })
  return res
}

function setCookie({ expDays = 30, name, value }) {
  let date = new Date()
  date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000)
  const cookieString = `${name}=${value}; expires=${date.toUTCString()}; SameSite=None; Secure`
  document.cookie = cookieString
}
