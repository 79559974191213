import styled from '@emotion/styled'

const Container = styled.div`
  display: inline-block;
  height: ${(props) => `${props.size}px`};
  overflow: hidden;
  width: ${(props) => `${props.size}px`};
`

const X = ({ className, color, onClick, size }) => (
  <Container className={className} onClick={onClick} size={size}>
    <svg height={size} width={size} viewBox={`0 0 ${size} ${size}`}>
      <polygon
        fill={color}
        points="96,14 82,0 48,34 14,0 0,14 34,48 0,82 14,96 48,62 82,96 96,82 62,48 "
        transform={`scale(${size / 96})`}
      />
    </svg>
  </Container>
)

X.defaultProps = {
  color: '#1a00ff',
  size: 20,
}

export default X
