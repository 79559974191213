export const initialLoadingState = false

const loadingReducer = (state = initialLoadingState, { type, loading }) => {
  switch (type) {
    case 'CLEAR_LOADING':
      return initialLoadingState
    case 'SET_LOADING':
      return loading
    default:
      return state
  }
}

export default loadingReducer
