export const fmtToMSS = (s) =>
  new Date(1000 * s).toISOString().substring(15, 19)

export const getAudioSrc = ({ playIndex, playlist = [] }) =>
  playlist[playIndex] ? playlist[playIndex].src : null

export const playInterrupt = (audioElement) => {
  audioElement.play().catch((e) => {
    if (e.code != DOMException.ABORT_ERR) {
      throw e
    }
  })
}

export const playlistFromList = (list) =>
  list
    ? list.ListItems.map((listItem) => ({
        artist: listItem.artist,
        cover: listItem.image,
        externalId: listItem.externalId,
        listId: list.id,
        releaseTitle: listItem.releaseTitle,
        src: listItem.streamURL,
        title: listItem.title,
        url: listItem.url,
      }))
    : []
