import Link from 'next/link'
import styled from '@emotion/styled'

const A = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.blue};

  &:hover {
    text-decoration: underline;
  }
`

const TextLink = ({ className, children, external, href }) => {
  if (external) {
    return (
      <A
        className={className}
        href={href}
        rel="noopener noreferrer"
        target="_blank"
      >
        {children}
      </A>
    )
  } else {
    return (
      <Link href={href}>
        <A className={className} href={href}>
          {children}
        </A>
      </Link>
    )
  }
}

export default TextLink
