import dynamic from 'next/dynamic'
import { createContext, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAudioSrc } from '../lib/player'
import { handleBrokenBandcampUrl } from '../lib/viewHelpers'
import {
  audioLoaded,
  playerNext,
  playerPause,
  playerPlay,
} from '../redux/actions'

const Player = dynamic(() => import('./Player'), { ssr: false })

export const AudioContext = createContext()

const PlayerWrapper = ({ children }) => {
  const audioRef = useRef(null)
  const dispatch = useDispatch()
  const playIndex = useSelector((state) => state.player.playIndex)
  const playlist = useSelector((state) => state.player.playlist)
  const srcFromState = getAudioSrc({ playIndex, playlist })
  const [src, setSrc] = useState(srcFromState)

  return (
    <>
      <AudioContext.Provider value={audioRef}>
        {children}
        <Player />
      </AudioContext.Provider>
      <audio
        ref={audioRef}
        src={src}
        onError={(error) => {
          handleBrokenBandcampUrl({
            externalId: playlist[playIndex].externalId,
            handleError: (error) => {
              dispatch(playerNext())
            },
            handleRemoved: () => {
              dispatch(playerNext())
            },
            handleUpdated: (updatedData) => {
              if (updatedData.streamURL) {
                setSrc(updatedData.streamURL)
              } else {
                dispatch(playerNext())
              }
            },
            url: srcFromState,
          }).then(() => {
            dispatch(playerPause())
            dispatch(playerPlay())
          })
        }}
        onCanPlay={() => {
          dispatch(audioLoaded())
        }}
      >
        No HTML5 support. Get with it.
      </audio>
    </>
  )
}

export default PlayerWrapper
