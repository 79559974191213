import Router from 'next/router'
import * as HTTP from '../../lib/http'
import { setErrors } from './errors'
import { setLoading } from './loading'

export const assignListToUser = ({ token, username }) => {
  return async (dispatch) => {
    const response = await HTTP.assignListToUser({ token, username })
    const body = await response.json()

    if (!response.ok) {
      dispatch(setLoading(false))

      if (response.status === 404) {
        return dispatch(
          setErrors({
            token: "We couldn't find a list for that token",
          })
        )
      } else {
        return dispatch(
          setErrors({
            token: `There was an error when checking your token: ${body.error.message}`,
          })
        )
      }
    }

    return Router.push(`/list/${body.slug}`)
  }
}
