export const breakpoints = {
  s: '350px',
  m: '550px',
  l: '700px',
}

export const media = {
  sUp: `@media screen and (min-width:${breakpoints.s})`,
  mUp: `@media screen and (min-width:${breakpoints.m})`,
  lUp: `@media screen and (min-width:${breakpoints.l})`,
}
