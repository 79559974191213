import styled from '@emotion/styled'
import UploadcareImage from '@uploadcare/nextjs-loader'
import User from './Icons/User'

const AvatarContainer = styled.div`
  background: ${({ theme }) => theme.colors.background};
  border-radius: 0.125rem;
  height: ${(props) => `${props.size}px`};
  width: ${(props) => `${props.size}px`};
`

const Placeholder = styled.span`
  display: inline-block;
  background: ${({ theme }) => theme.colors.blue};
  border-radius: 0.125rem;
  height: ${(props) => `${props.size}rem`};
  overflow: hidden;
  position: relative;
  width: ${(props) => `${props.size}rem`};
`

const PlaceholderIcon = styled(User)`
  position: absolute;
  top: ${(props) => `${props.size / 15}rem`};
  left: ${(props) => `${props.size / 21}rem`};
`

const Avatar = ({ className, size, src, username }) => {
  if (src) {
    return (
      <AvatarContainer className={className} size={size}>
        <UploadcareImage
          alt={username}
          height={size}
          layout="responsive"
          src={src + '.jpeg'}
          width={size}
        />
      </AvatarContainer>
    )
  } else {
    return (
      <Placeholder className={className} size={3}>
        <PlaceholderIcon color="white" size={3 * 3.2} />
      </Placeholder>
    )
  }
}

Avatar.defaultProps = {
  size: 48,
}

export default Avatar
