import { combineReducers } from 'redux'
import collectionsReducer from './collectionsReducer'
import errorsReducer from './errorsReducer'
import flashReducer from './flashReducer'
import loadingReducer from './loadingReducer'
import playerReducer from './playerReducer'
import searchReducer from './searchReducer'
import successReducer from './successReducer'

const reducers = {
  collections: collectionsReducer,
  errors: errorsReducer,
  flash: flashReducer,
  loading: loadingReducer,
  player: playerReducer,
  search: searchReducer,
  success: successReducer,
}

export default combineReducers(reducers)
