import { breakpoints, media } from './breakpoints'
import space from './space'

const colors = {
  background: '#f9f7fa',
  bandcampBlue: '#1DA0C3',
  blue: '#1a00ff',
  blueBoxShadow: 'rgba(26, 0, 255, 0.4)',
  gray: '#777',
  grayBoxShadow: 'rgba(0, 0, 0, 0.25)',
  lightGray: '#ccc',
  overlay: 'rgba(0, 0, 0, 0.7)',
  pink: '#d53a9d',
  red: '#c43e04',
  text: '#121212',
}

export default {
  breakpoints,
  colors,
  media,
  space,
}
