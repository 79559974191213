import Logo from '../components/Logo'
import { P } from '../components/typography'

const MaintenanceMessage = () => (
  <div className="ma4">
    <Logo />
    <P>
      We&apos;re down for some quick maintenance.
      <br />
      Please come back to us in a few minutes.
    </P>
  </div>
)

export default MaintenanceMessage
