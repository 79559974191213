import styled from '@emotion/styled'

const Container = styled.div`
  flex: 0 0 auto;
  font-size: 1.4rem;
  font-weight: 600;
  letter-spacing: -0.08rem;
  line-height: 1;
  margin-bottom: ${({ theme }) => theme.space[2]};
  margin-right: ${({ theme }) => theme.space[2]};

  div {
    color: ${({ theme }) => theme.colors.blue};
  }

  ${({ theme }) => theme.media.sUp} {
    font-size: 1.8rem;
  }

  ${({ theme }) => theme.media.mUp} {
    font-size: 2rem;
    margin-bottom: 0;
  }

  ${({ theme }) => theme.media.lUp} {
    font-size: 2.4rem;
  }
`

const Logo = () => (
  <Container>
    Buy Music <div>Club</div>
  </Container>
)

export default Logo
