export function moveItem(array, index, direction) {
  const newIndex = index + direction

  if (array.length < 2) return array
  if (newIndex < 0 || newIndex > array.length) return array

  const tempArray = [...array]
  const tempItem = tempArray.splice(index, 1)[0]
  tempArray.splice(newIndex, 0, tempItem)

  return tempArray
}
