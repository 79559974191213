import { useEffect, useState } from 'react'
import Head from 'next/head'
import url from 'url'
import { useRouter } from 'next/router'
import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'
import {
  clearErrors,
  clearFlash,
  clearLoading,
  clearSearch,
  clearSearchAndReturn,
  setFlash,
} from '../redux/actions'
import PlayerWrapper from '../components/PlayerWrapper'
import Flash from '../components/Flash'
import Header from '../components/Header'
import RollbarClient from '../components/RollbarClient'

const Main = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto 10rem;
  max-width: 52rem;
  overflow: hidden;
  padding: 0 1rem 1rem;
  width: 100%;
`

const LoadingOverlay = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  opacity: 0.5;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

const MainLayout = ({ children }) => {
  const Router = useRouter()
  const dispatch = useDispatch()
  const loading = useSelector((state) => state.loading)
  const { searchQuery } = useSelector((state) => state.search)
  const [isRouting, setIsRouting] = useState(false)

  const startRouting = () => setIsRouting(true)

  const stopRouting = (newUrl) => {
    // Clear all feedback on each route change: loading, errors, flash messaging

    if (newUrl.indexOf('search') === -1) {
      dispatch(clearSearch())
    }
    dispatch(clearErrors())
    dispatch(clearFlash())

    // Check URL for flash message
    const query = url.parse(newUrl, true).query
    if (query.flash && query.message) {
      dispatch(setFlash(query.message, query.flash))
    }
    dispatch(clearLoading())
    setIsRouting(false)
  }

  useEffect(() => {
    document.addEventListener('keydown', handleGlobalKeydown)
    Router.events.on('routeChangeStart', startRouting)
    Router.events.on('routeChangeComplete', stopRouting)

    return () => {
      Router.events.off('routeChangeStart', startRouting)
      Router.events.off('routeChangeComplete', stopRouting)
    }
  }, [])

  const handleGlobalKeydown = (e) => {
    if (e.keyCode === 27 && searchQuery) dispatch(clearSearchAndReturn())
  }

  return (
    <>
      <Head>
        <title>Buy Music Club</title>
        <meta
          content="A website for curating and sharing lists of independent music downloads available on Bandcamp."
          name="description"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta charSet="UTF-8" />
        <meta name="sourceApp" content="mobileWeb" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta name="twitter:site" content="@buymusicclub" />
        <link rel="shortcut icon" type="image/x-icon" href="/favicon.ico" />
        {process.env.NEXT_PUBLIC_DISABLE_ROLLBAR != 'true' && <RollbarClient />}
      </Head>
      <Main>
        <Header />
        <Flash />
        {children}
        {loading || (isRouting && <LoadingOverlay />)}
      </Main>
      <PlayerWrapper>
        <div id="fixed-footer" />
      </PlayerWrapper>
    </>
  )
}

export default MainLayout
