import Router from 'next/router'
import initialSearchState from '../reducers/searchReducer'

export const clearSearch = (search) => ({
  search: initialSearchState,
  type: 'CLEAR_SEARCH',
})

export const clearSearchAndReturn = () => {
  return async (dispatch, getState) => {
    const { search } = getState()
    const { preSearchPath, searchQuery } = search

    if (searchQuery) {
      dispatch(clearSearch())

      if (preSearchPath !== '/') {
        Router.push(preSearchPath)
      } else {
        Router.push('/')
      }
    }
  }
}

export const setSearch = (search) => ({
  search,
  type: 'SET_SEARCH',
})
