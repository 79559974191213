export const initialSuccessState = {}

const successReducer = (state = initialSuccessState, { type, success }) => {
  switch (type) {
    case 'LIST_SUCCESS':
      return success
    default:
      return state
  }
}

export default successReducer
