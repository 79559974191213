export const initialSearchState = {
  preSearchPath: null,
  searchQuery: '',
}

const searchReducer = (state = initialSearchState, { search, type }) => {
  switch (type) {
    case 'CLEAR_SEARCH':
      return initialSearchState
    case 'SET_SEARCH':
      return search
    default:
      return state
  }
}

export default searchReducer
